.noto-sans-jp {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

/* html {
  font-size: 16px;
} */

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100vh;
}

.list {
  font-size: 12px;
  margin: 20px;
}

.row {
  margin-bottom: 10px;
}

.row>div {
  display: inline-block;
  margin-right: 20px;
}

.row-num {
  font-weight: bold;
  width: 60px;
}

.row-name {
  color: blue;
  width: 200px;
}

.highcharts-container {
  display: flex;
  width: auto !important;
}

.highcharts-root {
  width: auto !important;
  max-width: 100% !important;
}

/* hide warning when using emulator */
.firebase-emulator-warning {
  display: none;
}

@media print {
  html {
    font-size: 12px !important;
  }

  html,
  body {
    width: 840px;
    margin: 0;
    padding: 0;
  }

  body,
  #root,
  .AC-viewLayout,
  .AC-pageContent,
  .MuiAppBar-root,
  .MuiToolbar-root,
  .AC-appBar {
    display: block !important;
    height: auto !important;
    background-color: transparent !important;
    position: static;
  }

  .AC-printOmit,
  .AC-pageBar,
  .AC-newVersionNotification,
  .AC-contextNavs,
  .MuiButton-root:not(.AC-residentButton),
  .MuiTooltip-popper {
    display: none !important;
  }

  .AC-extrasInfoGroup {
    display: flex !important;
  }

  #AC-pageScroll {
    height: auto !important;
    overflow-y: visible;
  }

  .AC-pageContent {
    padding: 0 !important;
  }

  .AC-appBar {
    position: relative !important;
    border-top: none !important;
  }

  .AC-appBar .AC-titleBlock {
    margin: 0;
    color: #000;
  }

  .AC-appBar .AC-titleBlock .AC-printAppbarLogo {
    display: inline-block !important;
  }

  .AC-appBarTitle {
    font-size: 2em;
    padding: .6em 0;
  }

  .AC-highlight {
    padding: 8px 16px;
  }

  .AC-boxSpacing {
    gap: 8px !important;
  }

  .MuiCardHeader-root {
    padding: 1em;
  }

  .MuiCardHeader-action {
    padding-right: 8px;
  }

  .MuiPaper-elevation {
    box-shadow: none !important;
    /* border: 1px solid lightgrey !important; */
    border-color: lightgrey !important;
  }

  .AC-pageContent .MuiPaper-elevation {
    border: 1px solid lightgrey !important;
  }

  .MuiDivider-root {
    border-color: lightgrey !important;
    border-bottom-width: 1px;
  }

  /* Sleep Report */

  .highcharts-container {
    width: auto !important;
    height: auto !important;
  }

  svg.highcharts-root {
    height: auto !important;
    width: 100% !important;
  }

  .AC-reportTable .AC-reportDay:nth-of-type(7) {
    /* background-color: red; */
    break-after: page !important;
  }

  .AC-collapsibleRow:not(.AC-chartRow) {
    flex-direction: row !important;
  }

}